import React from 'react'
import Layout from '../../components/Layout'
import useSiteMetadata from "../../components/SiteMetadata";
import {Helmet} from "react-helmet";

export default () => {
    const {title: siteTitle} = useSiteMetadata();

    return <Layout page="contact">
        <Helmet>
            <title>{siteTitle} - Contact - Thanks</title>
        </Helmet>
        <div className="osi-contents">
            <div className="content">
                <h1 className="title is-3">お問い合わせありがとうございます</h1>
                <hr />
                <p>お問い合わせの内容は担当者へ転送しております。</p>
                <p>お返事までお日にちを頂く場合がございますので、悪しからずご了承ください。</p>
            </div>
        </div>
    </Layout>
}
